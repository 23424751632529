import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createParams } from "../helpers";
import { path } from "ramda";
import { setSelected, setSelectedFilter } from "../../app/ui_actions";

export const AppLink = ({children,text,to,selected,history,type, ...props }) => {
  const dispatch = useDispatch();
  const url = useSelector(path(["ui","url"]));
  const navigate = useNavigate();

  createParams(url.params)
  const handleClick = (e) => {
    e.preventDefault();
    dispatch((type ? setSelectedFilter : setSelected)([selected,navigate,to,history,type]));
  }
  
  return <Link to={`${to}?${createParams(url.params)}`} onClick={handleClick} {...props}>{text || children}</Link>
};