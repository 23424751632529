import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Formik, Form } from 'formik';
import { useContext } from 'react';
import { UserContext } from '../App';
import * as Yup from 'yup';
import './part.css';
import { usePartsQuery, useUpsertPartsMutation } from '../app/api';
import { EditableText } from './EditableText';
import sectionize from 'remark-sectionize'
import rehypeRaw from 'rehype-raw';
import { keys, pick } from 'ramda';

export const Part = ({part_id,components,className}) => {
    const user = useContext(UserContext);
    const [editable,setEditable] = useState(false);
    const {data,isLoading} = usePartsQuery();
    const [upsertPart] = useUpsertPartsMutation();
    const validations = {content: Yup.string()};
    const submitFn = (values, { setSubmitting }) => {setTimeout(() => {
        upsertPart({id:part_id,...values});
        setSubmitting(false);}, 400);
        setEditable(false);}
    return isLoading ? <p>"Üks hetk ..."</p> :
        (editable && user) ? <div className='part section'><Formik 
            initialValues={{content:data?.[part_id]?.content}} 
            validationSchema={Yup.object(validations)} 
            onSubmit={submitFn}>
            <Form>
                <button className='hoverBtn' type="submit">Salvesta</button>
                <button className='hoverBtn' onClick={() => setEditable(false)}>Loobu</button>
                <EditableText className="part textArea" name="content"/>
            </Form>
        </Formik></div> : 
        <div className={'part section ' + className || ""}>
            {user ? <button className='overlayBtn' onClick={() => setEditable(true)}>
            </button>: null}
            <ReactMarkdown 
                className={"formattedText"}
                children={data?.[part_id]?.content}
                components={components}
                remarkPlugins={[sectionize]}
                rehypePlugins={[rehypeRaw]}/>
        </div>;
}

export const Editable = ({slug,className,FormElements,RenderElements,validations,queryFn,mutationFn}) => {
    const user = useContext(UserContext);
    const [editable,setEditable] = useState(false);
    const {data,isLoading} = (queryFn(slug) || {});
    const [upsertFn] = mutationFn();  
    const submitFn = (values, { setSubmitting }) => {setTimeout(() => {
        upsertFn({id:data.id,...pick(keys(validations),values)});
        setSubmitting(false)}, 100);
        setEditable(false);}
    return isLoading ? <p>"Üks hetk ..."</p> : 
    (editable && user) ? 
        <div className={className +" section"}>
            <Formik initialValues={data} validationSchema={Yup.object(validations)} onSubmit={submitFn}>
                <Form>
                    <button className='hoverBtn' type="submit">Salvesta</button>
                    <button className='hoverBtn' onClick={() => setEditable(false)}>Loobu</button>
                    <FormElements data={data} />
                </Form>
            </Formik>
        </div> : 
        <div className={className +" section"}>
            {user ? <button className='overlayBtn' onClick={() => setEditable(true)}></button> : null}
            <RenderElements data={data} />
        </div>;
}


export const EditableCollapsible = ({collapsed,slug,className,FormElements,RenderElements,validations,queryFn,mutationFn}) => {
    const user = useContext(UserContext);
    const [editable,setEditable] = useState(false);
    const {data,isLoading} = queryFn(collapsed ? null : slug);
    const [upsertFn] = mutationFn();  
    const submitFn = (values, { setSubmitting }) => {setTimeout(() => {
        upsertFn({id:data.id,...pick(keys(validations),values)});
        setSubmitting(false)}, 100);
        setEditable(false);}
    return isLoading ? <p>"Üks hetk ..."</p> : 
    (editable && user) ? 
        <div className={className +" section"}>
            <Formik initialValues={data[0]} validationSchema={Yup.object(validations)} onSubmit={submitFn}>
                <Form>
                    <button className='hoverBtn' type="submit">Salvesta</button>
                    <button className='hoverBtn' onClick={() => setEditable(false)}>Loobu</button>
                    <FormElements data={data} />
                </Form>
            </Formik>
        </div> : 
        <div className={className +" section"}>
            {(user && !collapsed) ? <button className='overlayBtn' onClick={() => setEditable(true)}></button> : null}
            <RenderElements data={data} />
        </div>;
}


