import {forceSimulation, forceLink,forceManyBody,forceCollide,forceCenter,forceX, forceY, 
  map as d3map} from 'd3';
import {compose as C,map as m,filter as f,groupBy as g,prop as p,juxt,sum,defaultTo,
  toPairs,values,__,uniqBy,chain, range,apply,flatten,identity as id, dropLast, uniq, 
  includes,reject,aperture,indexBy,head, keys, length as len, intersection, clamp,
  type,path,gt,inc,tap,
  propEq
} from "ramda"
import { r } from '../features/helpers';
import { node_r } from '../features/visual/render_fns';

const links_data = (filtered,valdkonnad,only_current) => {
  const projects_people = C(
    chain(([projekt_id,xs]) => m(([person_id,value]) => ({
      source:`p_${projekt_id}`,
      value,
      target:`i_${person_id}`}),xs)),
    toPairs,
    m(C(
      toPairs,
      m(C(r(2),sum,m(C(defaultTo(0),p("value"))))),
      g(p("User")),
      chain(x => x.Projekt_Inimene_all
        .filter(x => only_current ? x.people.active : true)
        .map(({people}) => ({...x,"User":people.id}))),
    )),
    g(p("id"))
  )
  const projektid_kategooriad = C(
    chain(([projekt,xs]) => m(([kategooria,value]) => ({
      source:`p_${projekt}`,
      value,
      target:`c_${kategooria}`}),xs)),
    toPairs,
    m(C(
      toPairs,
      m(C(r(2),sum,m(C(defaultTo(0),p("value"))))),
      g(p("kategooria")),
      chain(x => x.kategooriad.map(a => ({...x,"kategooria":a.id}))))),
    g(p("id"))
  )
  const category_tree = C(
    m(([target,source]) => ({source:`c_${source}`,value:50,target:`c_${target}`})),
    uniq,
    chain(C(aperture(2),m(head),p("path"))),
    chain(p("kategooriad"))
  )
  return C(flatten,juxt([projects_people,projektid_kategooriad,category_tree]))(filtered);
}

const nodes_data = (filtered,years,valdkonnad,only_current) => {
  const people = C(
    //f(C(len,intersection(years),p("years"))),
 //   only_current ? f(propEq("active",true)) : id,
    f(x => only_current ? x.active : true),
    uniqBy(p("id")),
    chain(C(
      m(({people:x}) => ({...x,
        id:`i_${x.id}`,
        value:20,
        group:"inimesed",
        years: m(p("year"),(x.too_aastad))})),
      p("Projekt_Inimene_all"),
    )),
  )
  const categories = C(
    ([objs,xs]) => m(([x,power,x_shift,y_shift]) => ({...x,
      ...(objs[x]),power,x_shift,y_shift, id:`c_${x}`,group:"field", value:20}),xs),
    juxt([
        C(indexBy(p("id")),uniq),
        C(uniq,chain(p("path")))
      ]),
 //   f(C(includes(__,valdkonnad),p("id"))),
    chain(p("kategooriad")),
  ) 
  const projects = m(x => 
    ({...x,id:`p_${x.id}`,group:"projekt",value:20}))
  return C(flatten,juxt([projects,people,categories]))(filtered);
}

export const calc_nodes_and_links = (projektid,{yearrange,selected,departments,only_current,selected_filter}) => {
  const add_categories = x => ({...x,
    kategooriad: C(
      ([paths,xs]) => reject(x => includes(x.id,paths),xs),
      juxt([C(uniq,chain(C(dropLast(1),m(head),p("path")))),id]),
      m(p("categories")),
      p("Projekt_Kategooria"),
    )(x)
  })
  const join_prop = (children,x,key,sub_key) => children[x.id] ? 
    C(uniqBy(path([sub_key,"id"])),chain(p(key)))([x,...children[x.id]]) : x[key]
  const between = ({low,high},x) => low <= x && x <= high;
  const around = ({low,high},{start,end}) => start<=low && high <= end;
  const valdkonnad = C(m(Number),keys,f(p("show")))(departments);
  const years = C(apply(range),values)(yearrange)
  const filter_period = (yearrange,x) => !x.start ? false :
     (between(yearrange,x.start) ||  between(yearrange,x.end) || around(yearrange,x));
  const add_children = C(
    ([xs,children]) => m(x => ({...x, 
      children: children[x.id],
      people: C(uniq,chain(C(path(["people","id"]))))(join_prop(children,x,"Projekt_Inimene","people")),
      categories: C(uniq,chain(C(m(head),path(["categories","path"]))))(join_prop(children,x,"Projekt_Kategooria","categories")),
      Projekt_Kategooria: join_prop(children,x,"Projekt_Kategooria","categories"),
      Projekt_Inimene_all: join_prop(children,x,"Projekt_Inimene","people")
    }),xs),
    juxt([reject(p("parent_id")),C(g(p("parent_id")),f(p("parent_id")))])
  )
  const filtered = C(
    f(C(len,intersection(valdkonnad),m(p("osakond_id")),p("kategooriad"))),
    m(add_categories),
    f(x => filter_period(yearrange,x) && (selected_filter ? 
      (selected ? includes(
        Number(String(selected.id).replace("c_","").replace("i_","")), 
        ((selected.group === "inimesed" || selected.group === "inimene") ? x.people : x.categories)
      ) : true) : true
    )),
    add_children
  )(projektid)


  const nodes = nodes_data(filtered,years,valdkonnad,only_current);
  const links = links_data(filtered,valdkonnad,only_current);

  //['id', 'value', 'ui_id', d.path, d.group]

  let nodes_copy = d3map(nodes, (x, i) => ({id: m(p("id"),nodes)[i],...x}));
  let links_copy = d3map(links, (_, i) => ({source: m(p("source"),links)[i], target: m(p("target"),links)[i]}));

  const strength_size = clamp(10,60,nodes.length/2)
  const simulation = forceSimulation(nodes_copy)
      .force("link",  forceLink(links_copy).strength(1).id(({index: i}) => m(p("id"),nodes_copy)[i]))
      .force("charge", forceManyBody().strength(10).distanceMax(300))
      .force("collide", forceCollide(node_r(selected,true)))
      .force("center", forceCenter())
      .force("x",forceX(d => d.group === "field" ?(5-d.path?.length || 0.2)*(d.x_shift)*0.2*strength_size : 0))
      .force("y",forceY(d => d.group === "field" ?(5-d.path?.length || 0.2)*(d.y_shift)*0.2*strength_size : 0))
      .stop();
  
  simulation.tick(300)

  return [nodes_copy,links_copy]
}