import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { NetworkGraph } from '../visual/graph.jsx';
import "./graph_outlet.css"
import { CategorySelect } from '../category_select/category_select.jsx';
import { Timeline } from '../timeline/timeline.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { map as m, path } from 'ramda';
import ToggleButton from '../toggle.jsx';
import { AppLink } from '../navigation/applink.jsx';
import { initialState } from '../../app/initial.js';
import { resetTopics, setAuthors, setPeriod, setSelectedFilter } from '../../app/ui_actions.js';
import { toggle_filters, toggle_map } from '../../app/ui_state.js';

export const GraphOutlet = ({type}) => {
    const filters = useSelector(path(["ui","filters"]));
    const screen_w = useSelector(path(["ui","width"]));
    const screen_h = useSelector(path(["ui","height"]));
    const selected = useSelector(path(["ui","selected"]));
    const only_current = useSelector(path(["ui","only_current"]));
    const show_map = useSelector(path(["ui","show_map"]));
    const show_filters = useSelector(path(["ui","show_filters"]));
    const departments = useSelector(path(["ui","departments"]));
    const i = initialState;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const toggle_authors = () => {
        dispatch(setAuthors([{authors:only_current ? true : undefined},navigate]))
    }
    const remove_filter = (key) => () => {
        const {fn,val} = {
            teema: {fn:setSelectedFilter,val:undefined},
            inimene: {fn:setSelectedFilter,val:undefined},
            filter: {fn:setSelectedFilter,val:undefined},
            periood: {fn:setPeriod,val:undefined},
            kaasautorid: {fn:toggle_authors,val:i.only_current},
            valdkonnad: {fn:resetTopics,val:m(x => ({...x,show:true}),departments)},
        }[key]
        dispatch(fn([val,navigate]));
    } 

    const headings = [
        { type: 'tood', label: 'Tööd' },
        { type: 'inimesed', label: 'Inimesed' },
        { type: 'teemad', label: 'Teemad' }
    ];

/*     const [headerClass, setHeaderClass] = useState('expanded');

    useEffect(() => {
        let lastScrollTop = 0;    
        const handleScroll = () => {
          const cur = window.scrollY;
            setHeaderClass(cur === 0 ? 'expanded' : 
                cur <= lastScrollTop ? 'compact' : 
                cur > 100 ? 'hidden' : 'compact');
          lastScrollTop = cur;
        };
        window.addEventListener('scroll', handleScroll);
        return () => {window.removeEventListener('scroll', handleScroll);};
      }, []);
 */
    const activeHeading = headings.find(heading => heading.type === type);

    return <>
        <div className="header-container" style={{ paddingRight: screen_w < 1200 ? 50 : 200 }}>{screen_w < 1300 ? (
            <div className="header-dropdown">
                <h1 className="active" onClick={toggleDropdown}>
                    {activeHeading.label} <span className="chevron">{dropdownOpen ? '▲' : '▼'}</span>
                </h1>
                {dropdownOpen && (<div className="dropdown-content">{
                    headings.filter(x => x.type !== activeHeading.type).map(heading => {
                        const key = `dropdown-${heading.type}`;
                        return (<AppLink key={key} selected={selected} to={`/${heading.type}`} 
                            className={type === heading.type ? 'active' : ''}>
                            <h1 onClick={toggleDropdown}>{heading.label}</h1>
                    </AppLink>)
                })}
                </div>)}
            </div>) : 
            (<div className="headers">{headings && type && headings.map(heading => {
                const key = `header-${heading.type}`;
                return (<AppLink key={key} selected={selected} to={`/${heading.type}`}>
                    <h1 className={type === heading.type ? 'active' : ''}>{heading.label}</h1>
                </AppLink>)
            })}
            </div>)}
            {(screen_w >= 720 || type === "tood") ? <div id="filters">
                {filters.map(({nimi,values}) => 
                <button className="filter" onClick={remove_filter(nimi,values[0].includes("kõik"))} style={{ whiteSpace: 'nowrap' }}>
                    <p>{nimi}: </p>
                    {(nimi !== "valdkonnad" ? values.join(', ') : m(([name,icon,id]) => 
                        <img loading="lazy" decoding="async" title={name} name={name} alt={name} style={{height:20}}
                            src={"/uploads/ui/"+icon} className="filter_delete_icon" key={"field_icon_" + id} />,
                    values))}
                    <span>✖</span>
                </button>)}
            </div> : null}
        </div>
        <div className="card-container" style={{marginTop:(screen_w >= 720 || type === "tood") ? 120 : 70, maxWidth: `max(${
            (screen_w > 1200 && show_map) ? "50%" : 
            (screen_w > 720 && show_map) ? "calc(50% - 50px)" :
            show_filters ? "calc(100% - 100px)" : 
            "100%"},calc(100% - 100vh))`}}>
        <Outlet/>
        </div>

        {screen_w > 720 ? 
            <div className={`graphPanel${(!show_map) ? " hidden" : ""}`} style={{
/*                 background: `radial-gradient(135% 130% at -10% -20%, ${
                        selected?.color || "#FFFFFF"
                    }1A 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF`, */
                paddingRight : (show_filters ? (screen_h <= 720 ? 325 : 100) : 0),
                right: show_map ? (show_filters ? (screen_h <= 720 ? 270 : 70) : 0) : "-120%"
                }}>
                <NetworkGraph/> 
            </div>
        : ""}
        <div className={`filterPanel${!show_filters ? " hidden" : ""}`} >
            <Timeline/>
            <CategorySelect/>
        </div>
        <div className="toggles" style={{right: screen_w > 720 && show_filters ? 150 : 30}}>
            {(screen_w > 720 && show_map) ? <ToggleButton text={"kaasautorid"} value={!only_current} fn={toggle_authors}/> : null}
            {screen_w > 720 ? <ToggleButton text={"kaart"} value={show_map} fn={toggle_map}/> : null}
            <ToggleButton text={"filtrid"} value={show_filters} fn={toggle_filters}/>
        </div>
    </>        
}