import React from "react";
import { useDepartmentsQuery, usePeopleQuery } from "../app/api";
import { Part } from "./part";
import './part.css';
import './jobs.css';
import { compose as C, filter as f, includes, indexBy, map as m, prop as p, propEq, reject, tap } from "ramda";
import { add_size } from "../features/helpers";
import { fullname } from "../features/people/people";

export const Jobs = () => {
  const {data:people,isLoading:load_p} = usePeopleQuery({}); 
  const {data:departments,isLoading:load_o} = useDepartmentsQuery(); 

  const managers = f(C(
    includes(3),
    m(p("osakond_id")),
    p("Inimene_Osakond")
  ));
  
  return (!load_o && !load_p) ? <>
    <h1 id="jobs">TULE TÖÖLE</h1>
    <div className="managers">
      <p style={{width: "100%", textAlign:"left"}}>Kui sooviksid Praxisesse tööle või praktikale tulla, anna endast märku sind enim huvitava valdkonna juhile.<br/>Kui ei tea, mis sulle kõige paremini sobiks, siis kirjuta Urmole.</p>
      {m(x => <div>
        <h3>{indexBy(p("id"),departments)[x.osakond_id].name}</h3>
        <img src={add_size(300,x?.pilt)} key={x?.slug+"-pic"} title={fullname(x)} 
          alt={fullname(x) + " profiilipilt"} decoding="async" className="profile_img" loading="lazy"/>
        <h4>{fullname(x)}</h4>
        <a href={"mailto:"+x.email}>{x.email}</a>
      </div>,people ? reject(propEq("osakond_id",3),managers(people)) : [])}
    </div>
  </> : null;
}