import { compose as C, all, curry, flatten, head, join, keys, map as m, equals as eq,
  reverse, split, tail, tap, unapply, values, zipObj, defaultTo, min, last, reject, isEmpty, 
  juxt, fromPairs, toPairs, filter as f, isNil,clamp,prop as p
} from "ramda";

import {forceSimulation, forceLink,forceManyBody,forceCollide,forceCenter,forceX, forceY, 
  map as d3map} from 'd3';
import { node_r } from "./visual/render_fns";

export const aC = C(curry((list, acc) => 
  list.reduce((acc, fn) => acc.then(fn), Promise.resolve(acc))), C(reverse, unapply(flatten)));
export const aConv = curry((merger, fns, arg) => 
  Promise.all(m(fn => Promise.resolve(arg).then(fn), fns)).then(args => merger(...args)));
export const aM = curry((fn, list) => Promise.all(m(fn,list)));
export const aObjM = curry(async (fn, list) => 
  zipObj(keys(list), await Promise.all(m(fn, values(list)))));

export const r = curry((pow,x) => Math.round(x*Math.pow(10,pow))/Math.pow(10,pow))

export const log = explanation => tap(x =>  (explanation,x));
export const set = (key,fn) => (state,{payload}) => {state[key] = fn(state[key],payload)}

export const slug = x => String(x)
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

export const all_same = x => all(eq(head(x)), tail(x));

export const add_size = (size,x) => C(
  x => `${join(".",reverse(tail(x)))}-${size}x${size}.${head(x)}`,
  reverse,
  split("."),
  defaultTo(""))(x)

export const scroll_into_view = (ref,shift) => ()=> {
  if (ref.current) {
    const max_scroll = document.documentElement.scrollHeight-300-window.innerHeight;
    const y = ref.current?.getBoundingClientRect()?.top + window.scrollY - shift;
    window.scrollTo({top: min(y,max_scroll), behavior: 'smooth'});
  }
}

const arrays = C(Array.isArray,last);
export const createParams = C(
    join("&"),
    reject(isEmpty),
    juxt([
      C(x => new URLSearchParams(x).toString(),fromPairs,reject(arrays)),
      C(m(C(join("&"),([key,val]) => m(x => `${key}[]=${encodeURIComponent(x)}`,val))),f(arrays))
    ]),
    reject(C(isNil,last)),
    toPairs
  )

export const isSafari = (x) => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? x+10 : x;
};


export const simulate_positions = (nodes,links,selected) => {
  let nodes_copy = d3map(nodes, (x, i) => ({id: m(p("id"),nodes)[i],...x}));
  let links_copy = d3map(links, (_, i) => ({source: m(p("source"),links)[i], target: m(p("target"),links)[i]}));

  const strength_size = clamp(10,60,nodes.length/2)
  const simulation = forceSimulation(nodes_copy)
      .force("link",  forceLink(links_copy).strength(1).id(({index: i}) => m(p("id"),nodes_copy)[i]))
      .force("charge", forceManyBody().strength(10).distanceMax(300))
      .force("collide", forceCollide(node_r(selected,true)))
      .force("center", forceCenter())
      .force("x",forceX(d => d.group === "field" ?(d.power || 0.2)*(d.x_shift)*0.2*strength_size : 0))
      .force("y",forceY(d => d.group === "field" ?(d.power || 0.2)*(d.y_shift)*0.2*strength_size : 0))
      .stop();
  
  simulation.tick(300)
  return [nodes_copy,links_copy]
}