
import { useSelector } from "react-redux";
import { Part } from "../../pages/part";
import { Link, Socials } from "../navigation/Navigation";
import "./footer.css";
import { path } from "ramda";
import { AppLink } from "../navigation/applink";

export const Footer = () => {
    const params = useSelector(path(["ui","url"]));
    return <footer>
        <div>
            <section>
                <p>SA Mõttekoda Praxis</p>
                <p>praxis@praxis.ee</p>
                <p>reg nr 90005952</p>
            </section>
            <section>
                <Link text="Avaleht" to="/"></Link>
                <Link text="Praxisest" to="/praxisest#"></Link>
                <Link text="Tule tööle" to="/kandideeri"></Link>
            </section>
            <section>
                <AppLink text="Tööd" to={`/tood`}></AppLink>
                <AppLink text="Inimesed" to={`/inimesed`}></AppLink>
                <AppLink text="Teemad" to={`/teemad`}></AppLink>
            </section>
            <section>
                <Link text="Loe uudiseid" to="uudised"></Link>
                <Link text="Liitu uudiskirjaga" to="/uudised?liitu=1"></Link>
                <Part part_id={30} className={"socials"} components={{a: Socials}} />
            </section>
        </div>
    </footer>
}

