import React, { useEffect, useRef, useState } from 'react';
import "./news.css";
import * as Yup from 'yup';
import sectionize from 'remark-sectionize'
import { useNewsQuery, useUpdateNewsMutation } from '../app/api';
import { useLocation, useParams } from 'react-router-dom';
import { compose as C, groupBy as g, toPairs, sort, descend,last, prop as p, head, map as m, path, 
    identity as id, addIndex, fromPairs, uniq, tap, indexBy,__,split,defaultTo} from 'ramda';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { EditableCollapsible } from './part';
import { Link } from 'react-router-dom';
import { EditableText } from './EditableText';
import { useDispatch, useSelector } from 'react-redux';
import { open_news } from '../app/ui_state';
import ReactMarkdown from 'react-markdown';
import { log } from '../features/helpers';

const url = "https://praxis.us4.list-manage.com/subscribe/post?u=83db137b4bc99ea8531555f74&amp;id=9493250305&amp;f_id=00533eebf0";

export const dd_mm = (d) =>  {
    const date = new Date(d);
    const estonianDate = new Date(date.toLocaleString("en-US", { timeZone: "Europe/Tallinn" }));
    const day = String(estonianDate.getDate()).padStart(2, '0');
    const month = String(estonianDate.getMonth() + 1).padStart(2, '0'); 
    return `${day}.${month}`;
}


const MaillistForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
        email.value.indexOf("@") > -1 && onValidated({ EMAIL: email.value });
    return (
        <div>
            {status === "sending" && <div style={{ color: "#4046ca" }}>Lisan...</div>}
            {status === "error" && (<div style={{ color: "#f68511" }}>Midagi läks valesti, palun proovi uuesti.</div>)}
            {status === "success" && (<div style={{ color: "#4046ca" }}>{
                message === "Thank you for subscribing!" ? "Lisatud. Aitäh!" :
                    message === "You're already subscribed, your profile has been updated. Thank you!" ? "Oled juba liitunud. Aitäh!" : message}</div>)}
            <input ref={node => (email = node)} type="email" placeholder="sinu e-mail" />
            <button onClick={submit}>Liitu</button>
        </div>
    );
};

export const News = () => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const openRef = useRef(null);
    const { data } = useNewsQuery();
    const { slug } = useParams();
    const dispatch = useDispatch();
    const [formVisible, setFormVisible] = useState(false);
    const opened_news = useSelector(path(["ui","open_news"]));
    const [opened_years,toggle_year] = useState({});
    
    const getYear = dateString => new Date(dateString).getFullYear();
    const getMonth = dateString => new Date(dateString).toLocaleString('et-EE', { month: 'long' });
    const isEmpty = (obj) => Object.keys(obj).length === 0;

    useEffect(() => {
        dispatch(open_news(slug));
        if(params.has("liitu")) setFormVisible(1);
        if (data && isEmpty(opened_years)) {
            const years = C(
                fromPairs,
                addIndex(m)((x, n) => [x, n === 0]),
                uniq,
                m(C(getYear, p("created_at"))),
            )(data);
            toggle_year(years);
        }
        const [year] = C(split("-"),defaultTo(""),p("created_at"),p(__,indexBy(p("slug"),data || [])))(slug);
        if (data && !isEmpty(opened_years)) {
            toggle_year(obj => ({ ...obj, [year]: true }));
        }
        if (openRef.current && slug && data) {
            setTimeout(() => {
                const y = openRef.current.getBoundingClientRect().top + window.scrollY - 20;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }, 100);
        }
    },[opened_news,openRef,slug,data]);

    const toggleFormVisibility = () => {
        setFormVisible(!formVisible);
    };

    const sortedGroupedData = data
        ? C(
            sort(descend(head)),
            toPairs,
            m(C(
                sort(descend(C(p("created_at"),last))),
                toPairs,
                m(sort(descend(p('created_at')))),
                g(x => getMonth(x.created_at))
            )),
            g(x => getYear(x.created_at))
        )(data)
        : [];

    return data ? (
        <>
            <h1 className="news-heading">UUDISED</h1>
            <button className="inline-news-link" onClick={toggleFormVisibility}>
                {formVisible ? "Peida vorm" : "Liitu uudiskirjaga"}
            </button>
            <div className={`join-maillist ${formVisible ? 'visible' : 'hidden'}`}>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <MaillistForm
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    )}
                />
            </div>
            <div className="newslist">{sortedGroupedData.map(([year, months]) => (
                <div key={year} className={`year-block ${opened_years[year] ? "opened-year" : ""}`}>
                    <h2 onClick={() => {toggle_year({...opened_years, [year]:!opened_years[year]})}}>{year}</h2>
                    {months.map(([month, monthItems]) => (
                        <div key={month} className="month-block">
                            <h3>{month}</h3>
                            <ul className="news-grid">{monthItems.map(x => {
                                const closed = opened_news !== x.slug;
                                return <li  
                                    ref={!closed ? openRef : null}
                                    className={closed ? "" : "opened"} key={x.slug}>
                                    {closed ? <>
                                        <h4><span><b>{dd_mm(x.created_at)}</b></span>{x.title}</h4>
                                        <Link  className="newslink" to={`/uudised/${x.slug}`}/>
                                     </> :
                                    <EditableCollapsible 
                                        slug={x.slug}
                                        key={x.slug}
                                        collapsed={closed}
                                        className="singlenews"
                                        validations={{title: Yup.string(),content: Yup.string()}}
                                        queryFn={useNewsQuery}
                                        mutationFn={useUpdateNewsMutation}
                                        FormElements={({data}) => <>
                                            <EditableText name="title" className="h4_input"/>
                                            <input type="date" name="created_at" lang="et-EE" value={data[0].created_at}/>
                                            <EditableText name="content"/>
                                        </>}
                                        RenderElements={({data}) => <>
                                            <Link to={`/uudised`} className="news_back">
                                                <img src="/back.svg" alt="back button" className="back"/>
                                            </Link>
                                            <h4><span><b>{dd_mm(data?.[0]?.created_at)}</b></span>{data?.[0]?.title}</h4>
                                            <ReactMarkdown className={"formattedText"} remarkPlugins={[sectionize]} 
                                                children={data?.[0]?.content}/>
                                        </>}/>}
                                </li>})}
                            </ul>
                        </div>              
                    ))}
                </div>))}
            </div>
        </>
    ) : null;
};
