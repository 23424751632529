import {easeQuadIn, easeQuad, easeQuadOut, Delaunay, select} from 'd3';
import { __, prop as p } from 'ramda';

export const size_and_pos = r_fn => x => {
  return x
  .attr("r", d => r_fn(d))
  .attr("cx", d => d.x)
  .attr("cy", d => d.y)
}

const sq = x => Math.pow(x,2);

const hypotenuse = (a,b) => Math.sqrt(sq(a.x-b.x) + sq(a.y-b.y));

const curved_line = ({source:s,target:t}) => {
      const dr = hypotenuse(s,t);
      return `M${s.x},${s.y}A${dr},${dr} 0 0,1 ${t.x},${t.y}`}

export const len_and_curve = fn => x => {
  x.attr("d", curved_line)
    .each(function(d) {
      const totalLength = this.getTotalLength();
      select(this).datum({...d, totalLength });}) 
    .attr("stroke-dasharray",fn)
}
export const grow = (fn,r_fn,delay,duration) => x => {
  x.call(fn).attr("r",0).transition().ease(easeQuadIn).delay(delay).duration(duration).attr("r",r_fn);
}
export const line_grow = (fn,delay,duration) => x => {
  x.call(fn)
    .attr("stroke-dashoffset", d => d.totalLength)
    .transition().ease(easeQuadIn).delay(delay).duration(duration)
    .attr("stroke-dashoffset", d => 0);
}
export const transitioned = (fn,delay,duration) => x => x
  .transition().ease(easeQuad).delay(delay).duration(duration)
  .call(fn);

export const line_shrink = (fn,duration) => x => x
  .attr("stroke-dashoffset", d => 0)
  .transition().ease(easeQuadOut).duration(duration)
  .attr("stroke-dashoffset", d => d.totalLength)
  .remove();

export const shrink = (fn,duration) => x => x
  .transition().ease(easeQuadOut).duration(duration)
  .call(fn).attr("r",0)
  .remove();

export const remove_highlights = (nodes,links,d) => {
  nodes.style('opacity', node_opacity(undefined));
  links.style('stroke-opacity', 0);
}
export const highlight_one = (node,link,links,d,categories) => {
  const the_id = (d.parent_id && d.group === "projekt") ? "p_"+d.parent_id : d.ui_id || d.id;
  const highlight = links
  .map(e => (d.group !== "field" && e.source?.id === the_id) ? e.target?.id : 
      (e.target?.id === the_id) ? e.source?.id : 0)
  .filter(x => x);
  const highlighted = x => highlight.includes(x.id) || x.id === the_id; 
  node.style("opacity", x => highlighted(x) ? 1 : 0.1)
  link.style('stroke-opacity',x => (
    (d.group !== "field" && x.source?.id === the_id) || x.target?.id === the_id) ? 0.8 : 0)
}
export const node_opacity = selected => d => {
  return ["field","inimesed"].includes(d.group) ? 1 : selected === undefined ? 0.8 : 1;
}
export const node_fill = categories => d => {
  const category = categories[d?.id];
  return d.group === "field" ? `url(#${category?.osakond_id})` : 
    (d.group === "inimesed" && d.slug) ? `url(#${d.slug || "placeholder"})` : 
    d.color;
}
export const node_r = (selected,large) => d => {
  return d.group === "field" ? (large  ? 7 : 0) + 7 + (d.power)*4 :  
      d.group === "inimesed" ? ((large || selected?.id === d.id) ? 7 : 0) + ((d.pilt === null || d.active === false) ? 14 : 21) : 
                            (large ? 7 : 0) + 14 ;
}
export const voronoi = (nodes,w,h) => 
  Delaunay
    .from(nodes,p("x"),p("y"))
    .voronoi([ -w / 2, -h / 2, w, h])