import React, {useEffect, useRef} from 'react';

import { compose as C, assoc, defaultTo, filter as f, includes, map as m, path, 
    prop as p, propEq, chain, identity as id, groupBy as g,length, uniq, join, min} from 'ramda';
import {  useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useCategoriesQuery } from '../../app/api.js';

import './../../pages/part.css';
import "./categories.css";
import { scroll_into_view } from '../helpers.js';
import { AppLink } from '../navigation/applink.jsx';

const buildTree = (items, parentId = null, depth = 0, maxDepth = 4) => C(
    m(item =>assoc('children',depth < maxDepth ? buildTree(items, item.id, depth + 1, maxDepth) : [],item)),
    f(propEq('parent_id', parentId)),
)(items);

const category_tree = xs => C(
    m(root => assoc('children', buildTree(xs, root.id), root)), 
    f(propEq('parent_id', null))
)(xs)


const Pill = ({level,grey,selected,years,node}) => 
    <div className={"pill" + (selected?.slug === node.slug ? " opened" : "")} style={{ 
        border: selected?.slug !== node.slug ? "none" : "2px solid black",
        margin: level === 1 ? "2px" : "4px 8px 4px 4px",
        opacity: !grey ? "1" : "0.3"}}>

        <AppLink to={`/teemad/${node.slug}`} selected={node}>
            <img src={"/uploads/ui/"+node.icon} decoding="async" loading="lazy" className="category" />
            <span>{node.name}</span>
        </AppLink>
        {selected?.slug !== node.slug ? "" : <div className="show_projects">
            <AppLink type="all" to="/tood/" selected={node}>kõik tööd</AppLink>
        </div>
        }
    </div>;

const renderTree = (data,level=5,category_nodes,selected,openRef,years) => (<>
      {data.map(node => (
        <div className={node.children.length ? "category_group" : ""} key={node.id} style={{
            display: node.children.length ? "block" : "inline-block",marginLeft:30,marginTop:level}}>
          <div ref={selected?.slug === node.slug ? openRef : undefined} 
            style={{display: node.children.length ? "block" : "inline-block",}} >
            <Pill  selected={selected} node={node} level={level-1} years={years}
                grey={!includes("c_"+node.id,category_nodes)}/>
          </div>
          {node.children && renderTree(node.children,level-1,category_nodes,selected,openRef,years)}
        </div>
      ))}
    </>
  );

export const Categories = () => {
    const openRef = useRef();
    const selected = useSelector(path(["ui","selected"]));
    const nodes = useSelector(path(["ui","nodes"]));
    const dispatch = useDispatch();
    const params = useSelector(path(["ui","url"]));
    const categories = useCategoriesQuery(); 
    const timeline_range = useSelector(path(["ui", "yearrange"]));
    const category_nodes = C(m(p("id")),f(propEq("group","field")))(nodes || [])

    useEffect(() => {
        if (openRef.current) {setTimeout(scroll_into_view(openRef,200), 0);}
    }, [dispatch, selected]);
    return <>
    <ul className="categorylist">
        {categories?.data ? m(root => <li key={root.id} style={{listStyle:"none",marginTop:"40px"}}>
            <div className={root.children.length ? "category_group" : ""} 
                ref={selected?.slug === root.slug ? openRef : undefined} key={root.slug}>
                <div  style={{display:"block"}} >
                    <Pill selected={selected}  node={root}  level={5}  years={timeline_range} params={params} 
                        grey={!includes("c_"+root.id,category_nodes)} />
                </div>
                {root.children && renderTree(root.children, 4,category_nodes,selected,openRef,timeline_range)}
            </div></li>,category_tree(categories.data || []))
        : ""}
    </ul> 
    </>
}