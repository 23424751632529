import React from "react";
import { Part } from "./pages/part";
import './home.css';
import { NetworkGraph } from "./features/visual/graph";
import { Link } from "react-router-dom";
import { map as m, path } from "ramda";
import { useSelector } from "react-redux";
import { useNewsQuery } from "./app/api";
import { dd_mm } from "./pages/news";
import { AppLink } from "./features/navigation/applink";


export const Home = () => {
  const width = useSelector(path(["ui","width"]));
  const selected = useSelector(path(["ui","selected"]));
  const { data:news } = useNewsQuery();
  return (<>
    <div id="home-container">
      <div className={width > 768 ? "col" : ""}>
        <Part part_id={1}/>
        <div className="home-links">
          <AppLink className={"inline-link"} to={`/tood`} selected={selected}>TÖÖD</AppLink>
          <AppLink className={"inline-link"} to={`/inimesed`} selected={selected}>INIMESED</AppLink>
          <AppLink className={"inline-link"} to={`/praxisest`} selected={selected}>MEIST</AppLink>
        </div>
      </div>
        {width > 768 ? <NetworkGraph selection_off={true}/> : null}
    </ div>
    <div className="news">
      <Link to="/uudised"><h2>UUDISVOOG</h2></Link>
      <ul>
        {m(x => <li key={x.slug}>
          <Link to={`/uudised/${x.slug}`}>
            <h3><span><b>{dd_mm(x.created_at)}</b></span>{x.title}</h3>
          </Link>
        </li>,(news || []).slice(0,4))}
      </ul>
    </div>
  </>);
}



