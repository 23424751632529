import { filter as f, identity as id, join, map as m,uniq, path, __,propEq} from "ramda";
import { useLocation, useNavigate } from "react-router-dom"
import { useProjectsQuery,useCategoriesQuery } from "../../app/api";
import "./project.css";
import './../../pages/part.css';
import { useSelector } from 'react-redux';
import { useEffect, useRef } from "react";
import { ProjectTabs } from "./project";
import { scroll_into_view } from "../helpers";

export const Projectlist = () => {
    const selected = useSelector(path(["ui","selected"]));
    const nodes = useSelector(path(["ui","nodes"]));
    const projects = useProjectsQuery();
    const openedProjectRef = useRef(null);

    useEffect(() => {
        if (openedProjectRef.current && nodes) {
            const refEl = openedProjectRef.current;
            const marginTop = parseInt(refEl.style.marginTop)+180 || 180;
            setTimeout(scroll_into_view(openedProjectRef,marginTop),1)}
        else {
            window.scrollTo(0, 0);
        }
        }, [openedProjectRef,selected,nodes]);

    return <ul className="projects">{projects?.data ? m(x => {
        const closed = selected?.slug !== x.slug && selected?.slug?.split("/")[0] !== x.slug;
        return <li 
            style={{marginTop:closed ? 0 : (x?.children?.length || 0) * 60}}
            className={closed ? "collapsed" : ""}
            ref={!closed ? openedProjectRef : null}
            key={x.slug} >
            <ProjectTabs main={x} selected={selected} closed={closed}/>
        </li>},
    f(x => x.group === "projekt", nodes)) : ""}
    </ul>
}