import { compose as C, __, filter as f, fromPairs, groupBy as g, head, identity, 
  ifElse, includes, join, last, map as m, prop as p, partition, props, uniq, values
} from 'ramda';
import { set_url, set_filters, set_nodes, set_links } from './ui_state';
import { createParams } from '../features/helpers';
import { initialState } from './initial';
import { calc_nodes_and_links } from './simulation';

export const project_in_range = (yearrange, x) => {
    const between = ({ low, high }, x) => low <= x && x <= high;
    const around = ({ low, high }, { start, end }) => start <= low && high <= end;
    return !x?.start ? false :
      (between(yearrange, x.start) || between(yearrange, x.end) || around(yearrange, x));
};
export const yearrange_selection = ({ timeline_range, yearrange, selected }, type) => {
    const { yearrange: { low: i_low, high: i_high } } = initialState;
    const min_low = head(timeline_range);
    const max_high = last(timeline_range);
    const is_full_range = min_low === yearrange.low && max_high === yearrange.high;
    const is_initial = i_low === yearrange.low && i_high === yearrange.high;
    const reset_range = (type === undefined || is_full_range || is_initial);
    const initial_range = { low: i_low, high: i_high };
    const in_range = project_in_range(reset_range ? initial_range : yearrange, selected);
    return {
      is_full_range, is_initial, reset_range, in_range, initial_range,
      undefined_range: { low: undefined, high: undefined },
      full_range: { low: min_low, high: max_high }
    };
};
export const recalc = (state) => (dispatch) => {
  const { api: { queries: { "projects(undefined)": { data } } }, ui } = state;
  const [nodes, links] = calc_nodes_and_links(data, ui);
  dispatch(set_nodes(nodes));
  dispatch(set_links(links));
};
export const recalcAndUpdate = (stateUpdater) => ([val, navigate, path, history]) => (dispatch, getState) => {
  if (stateUpdater) dispatch(stateUpdater(val));
  dispatch(setUrl([val, navigate, path, history ]));
  dispatch(recalc(getState()));
  dispatch(setFilters());
};
export const getSelection = (allData, params, project, tab) => {
  const ui_id_match = xs => xs.find(x => x.ui_id === params.selected);
  const tab_match = xs => xs.find(x => x.slug === project + "/" + tab);
  const slug_match = xs => xs.find(x => x.slug === project);
  return ui_id_match(allData) || tab_match(allData) || slug_match(allData);
};
export const parseUrlParams = (urlParams) => {
  const parseNum = ifElse(C(isNaN, Number), identity, Number);
  const params = C(
    ([arrays, simple]) => ({
      ...C(m(m(C(parseNum, last))), g(head), m(([key, val]) => [key.replace("[]", ""), val]))(arrays),
      ...C(m(C(parseNum, last, last)), g(head))(simple)
    }),
    partition(C(includes("[]"), head))
  );
  return params([...urlParams]);
};
export const calculateYearRange = (params, timelineRange, selection) => {
  return (params.high || params.low) ? { high: Number(params.high), low: Number(params.low) } :
    params.filter === "all" ? { high: last(timelineRange), low: head(timelineRange) } :
    (selection && selection.end < initialState.yearrange.low) ? 
        { low: selection.end || selection.start, high: selection.end || selection.start } :
    initialState.yearrange;
};
export const initializeDepartments = (departments, params) => {
  return C(
    fromPairs,
    m(x => [x.id, { ...x, show: params.topics ? !includes(x.id, params.topics) : true }]),
    f(p("icon"))
  )(departments);
};
export const setUrl = ([params, navigate, path, history]) => (dispatch, getState) => {
  const { ui: { url } } = getState();
  const updatedUrl = {
    params: { ...url.params, ...params },
    path: path || url.path
  };
  dispatch(set_url(updatedUrl));
  navigate(`${updatedUrl.path}?${createParams(updatedUrl.params)}`, { replace: history, state: updatedUrl.params });
};
export const setFilters = () => (dispatch, getState) => {
  const { ui: { selected, selected_filter, departments, 
    only_current, width, timeline_range, yearrange: { low, high } } } = getState();
  const department_icons = C(values, m(props(["name", "icon", "id"])), f(p("show")))(departments);
  const { yearrange: { low: i_low, high: i_high } } = initialState;
  const selected_filtering = (group, nimi) => (selected_filter && selected?.group === group) ? 
    [{ nimi, values: [selected?.name + ((head(timeline_range) === low && last(timeline_range) === high) ? " kõik" : "")] }] : [];
  const filtrid = [
    ...(i_low !== low || i_high !== high) ? 
      [{ nimi: "periood", values: [join("-", uniq([low, high]))] }] : [],
    ...(selected_filtering("inimene", "inimene")),
    ...(selected_filtering("inimesed", "inimene")),
    ...(selected_filtering("field", "teema")),
    ...(department_icons.length !== values(departments).length ? [{ nimi: "valdkonnad", values: department_icons }] : []),
    ...(!only_current && (width > 720) ? [{ nimi: "kaasautorid", values: ["näita kõiki"] }] : [])
  ];
  dispatch(set_filters(filtrid));
};