import React, { useState } from 'react';
import { Part } from './part';
import "./power.css";
import Modal from '../features/modal/modal';
import { addIndex, map as m } from 'ramda';
import Slider from 'react-slick';


const Video = ({ src, poster, onClick }) => (
    <img
        src={poster}
        alt="Video poster"
        style={{ cursor: 'pointer', width: '100%' }}
        onClick={onClick}
    />
);

export const Power = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');
    const [videoPoster, setVideoPoster] = useState('');
    const ids = [40,41,42,43];
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                },
              },
          ],}

    const handleVideoClick = (src, poster) => {
        setVideoSrc(src);
        setVideoPoster(poster);
        setIsModalOpen(true);
    };
    return <>
        <div className="power-intro introduction">
            <Part part_id={34} className="full_width"/>
            <Part part_id={35}/>
        </div>
        <div className="power-job-card">
            <img src="/uploads/2024/08/grain_pattern.png" decoding="async" loading="lazy" className="pattern" />
            <Part part_id={36}/>
            <a href={"/uploads/2024/08/Praxis. Tippjuhiks saamise teed - mehed juhtuvad juhiks, naised juhivad end juhiks.pdf"} target="_blank" rel="noopener noreferrer">PRAXISE UURINGU RAPORT</a>
        </div>
        <div className="power-job-card">
            <img src="/uploads/2024/08/grain_pattern.png" decoding="async" loading="lazy" className="pattern" />
            <Part part_id={37}/>
            <a href={"/uploads/2024/08/Praxis. Nais- ja meesjuhtide meediakajastuste ja sotsiaalmeediakasutuse analüüs.pdf"} target="_blank" rel="noopener noreferrer">MEEDIAANALÜÜSI RAPORT</a>
        </div>
        <div className="power-full-width">
            <div>
                <h2>VIDEOD</h2>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <video width="100%" controls autoPlay poster={videoPoster}>
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Modal>
                <div className="three-col no-padding videos">
                    <Part key={"part_45"} part_id={45} components={{
                        video: ({node, ...props}) => 
                            <Video {...props} onClick={() => handleVideoClick(props.src, props.poster)} />,
                    }}/>
                    <Part key={"part_39"} part_id={39} components={{
                        video: ({node, ...props}) => 
                            <Video {...props} onClick={() => handleVideoClick(props.src, props.poster)} />,
                    }}/>
                    <Part key={"part_38"} part_id={38} components={{
                        video: ({node, ...props}) => 
                            <Video {...props} onClick={() => handleVideoClick(props.src, props.poster)} />,
                    }}/>
                </div>
            </div>
        </div>
        <div className="slider-container power-media">
            <h2>TEEMA KAJASTUSED</h2>

            <Slider {...settings}>
                {addIndex(m)((id,n) => 
                    <Part part_id={id} components={{a: ({href,children}) => <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>}}/>
                ,ids)}
            </Slider>
        </div>
        <div className="power-logod">
            <Part part_id={44} />
        </div>
    </>
}