import { compose as C, __, head, join, map as m, path, prop as p, split, tail, toUpper, toLower, omit, toPairs} from 'ramda';
import React, { createElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { menu_open,show_info } from '../../app/ui_state';
import './navigation.css';
import { Part } from '../../pages/part';
import Instagram from '../../instagram';
import Facebook from '../../facebook';
import Twitter from '../../twitter';
import Linkedin from '../../linkedin';
import { AppLink } from './applink';


const nav_with_close = dispatch => x => {
    x.stopPropagation(); 
    dispatch(menu_open(false));
    dispatch(show_info(false));
}

export const Link = ({to,text}) => {
    const dispatch = useDispatch();
    return createElement(/#/.test(to) ? HashLink : NavLink,{smooth:"true", onClick:nav_with_close(dispatch), to},text)
}

const Locations =({href,children}) => {
    const texts = split(",",head(children));
    return <span className="location" key={"location_"+head(texts)}>
        {m(x => /^[+()0-9-\s]*$/.test(x) ? <a key={x} href={"tel:"+x}>{x}</a> : <span key={x}>{x}</span>,texts)}
        <br/><a className="directions" href={href} rel="noreferrer" target="_blank">JUHATA</a>
    </span>
}

export const Socials =({href,children}) => {
    const socials = { instagram: Instagram,  facebook: Facebook, linkedin: Linkedin, twitter: Twitter}
    const Element = C(p(__,socials),toLower,head)(children)
    return <a href={href}>{!!Element ?<Element className="social">{children}</Element> : children}</a>;
}

const TitledText =({children}) => {
    return children;
}

const Navigation = () => {
    const dispatch = useDispatch();
    const opened = useSelector(path(["ui","menu_open"]));
    const selected = useSelector(path(["ui","selected"]));
    const showing_info = useSelector(path(["ui","show_info"]));
    
    
    return <div className={"sidepanel" + (opened ? " opened" : "") + (showing_info ? " info" : "")} >
        <div className="overlay" onClick={nav_with_close(dispatch)}></div>
        <nav onClick={(x) =>{x.stopPropagation(); dispatch(menu_open(false));dispatch(show_info(false));}}>
            <ul>
                <li><Link text="AVALEHT" to="/"></Link></li>
                <li><Link text="PRAXIS" to="/praxisest"></Link></li>
                <ul>
                    <li className='subitem'><Link text="Põhimõtted" to="/praxisest#pohimotted"></Link></li>
                    <li className='subitem'><Link text="Valdkonnad" to="/praxisest#valdkonnad"></Link></li>
                    <li className='subitem'><Link text="Teenused" to="/praxisest#teenused"></Link></li>
                </ul>
                <li><AppLink selected={selected} text="AVASTA" to={`/tood`} /></li>
                <ul>
                    <li className='subitem'><AppLink selected={selected} text="Tööd" to={`/tood`}/></li>
                    <li className='subitem'><AppLink selected={selected} text="Inimesed" to={`/inimesed`}/></li>
                    <li className='subitem'><AppLink selected={selected} text="Teemad" to={`/teemad`}/></li>
                </ul>
                <li><Link text="LOE" to="uudised"></Link></li>
                <ul>
                    <li className='subitem'><Link text="Uudised" to="/uudised"></Link></li>
                    <li className='subitem'><Link text="Uudiskirjad" to="/uudised?liitu=1"></Link></li>
                </ul>
                <li><Link text="TULE TÖÖLE" to="kandideeri"></Link></li>
            </ul>
        </nav>
        <div className="information" onClick={() =>{dispatch(menu_open(false));dispatch(show_info(false));}}>
            <h2>SA Mõttekoda Praxis</h2>
            <a href="mailto:praxis@praxis.ee">praxis@praxis.ee</a>
            <Part part_id={30} components={{a: Socials}} />
            <Part part_id={32} components={{a: Locations}}/>
            <div className="requisites">
                <div className='two_in_row'>
                    <div>
                        <p className='label'>REG NR</p>
                        <p>90005952</p>
                    </div>
                    <div>
                        <p className='label'>KMKR</p>
                        <p>EE100951538</p>
                    </div>
                </div>
                <div>
                    <p className='label'>LHV KONTO</p>
                    <p>EE847700771007487052</p>
                </div>
                <div>
                    <p className='label'>BIC / SWIFT</p>
                    <p>LHVBEE22</p>
                </div>
            </div>
        </div>
    </div>;
}

export default Navigation;