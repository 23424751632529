import React from 'react';
import { Part } from './part';
import {Fields, Services } from './swiper';
import { map as m } from 'ramda';
import "./about.css";

export const Desc = ({props}) => {
    return <>
        <p>Desc component.</p>
        <p>{JSON.stringify(props)}</p>
    </>
}

export const About = () => 
    <>
        <h1 className="about-title">PRAXISEST</h1>
        <div className="three-col introduction">
            <Part part_id={2}/>
            <Part part_id={3}/>
            <Part part_id={4}/>
        </div>
        <div id="pohimotted" className="three-col principles">
            <div className="colored-background">
                {m(([x,y,cls]) => <div className={cls} style={{top:y+"%", left: x+"%"}}></div>,
                [[15,0,"outer"],[50,110,"outer"],[95,20,"outer"],
                [15,0,"inner"],[50,110,"inner"],[95,20,"inner"]])}
            </div>
            <Part key={"part_8"} part_id={8}/>
            <Part key={"part_9"} part_id={9}/> 
            <Part key={"part_10"} part_id={10}/>
        </div>
        <Fields id="valdkonnad" className="single-col"/>
        <Services id="teenused" className="single-col"/>
    </>

