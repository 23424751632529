import React, {useRef, useState} from 'react'; 
import { addIndex, map as m } from 'ramda';
import Slider from "react-slick";
import { useProjectsQuery } from '../app/api';
import { Part } from './part';

import "./part.css";
import "./swiper.css";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";
import { Authors } from '../features/project/authors';
import { Years } from '../features/project/years';
import { CategoryIcons } from '../features/project/category_icons';

export const Samples = ({href,children}) => {
    const {data} = useProjectsQuery(href);
    if (data) {
        return <a className="swiper_project" href={"tood/"+href}>
            <span>{children}</span> 
            <Authors data={data[0]}/>
            <Years start={data[0].start} end={data[0].end}/>
            <CategoryIcons data={data[0]}/>
        </a>
    }
    return null;
}
export const P_to_div = ({children}) => {
    return <div>{children}</div>
}
export const Empty = () => {
    return <>
    </>
}
const accordionSection = (openIndexes,setOpenIndexes,methods) => (x) => {
    const line = x.node.children[0].position.start.line;
    return <section 
        onClick={x => {setOpenIndexes({...openIndexes,[methods]: line})}} 
        className={line === openIndexes[methods] ? "open" : "closed"}>
        {x.children}
    </section>             
}

export const Services = ({className,id}) => {
    const ids = [[11,12,13],[14,15,16],[17,18,19]];
    const [openIndexes, setOpenIndexes] = useState({12:1,15:1,18:1});
    const [slideIndex, setSlideIndex] = useState(0);

    let sliderRef2 = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setSlideIndex(next)}

    const SlideSelect = (n) => ({children}) => {
        
        return <h3 
        className={slideIndex === n ? "active-header" : null} 
        onClick={() => {sliderRef2.slickGoTo(n)}}>{children}</h3>
    }
    return <div className={className} id={id}>
        <h2 className={"about-subtitle"}>Teenused</h2>
        <div className="headers">
            <Part part_id={11} key={"service_header_11"} components={{h3:SlideSelect(0),p:Empty}}/>
            <Part part_id={14} key={"service_header_14"} components={{h3:SlideSelect(1),p:Empty}}/>
            <Part part_id={17} key={"service_header_17"} components={{h3:SlideSelect(2),p:Empty}}/>
        </div>
        <div className="slider-container">
            <Slider
                ref={x => {sliderRef2 = x}}
                {...settings}>
                {addIndex(m)(([description,methods,samples],n) => 
                    <div key={"service_" + n}>
                        <div className="three-col swiper-padding service">
                            <Part part_id={description} className="description" components={{h3: Empty}}/>
                            <Part part_id={methods} className="methods" components={{section: accordionSection(openIndexes,setOpenIndexes,methods)}}/>
                            <div>
                                <h4 style={{marginBottom: 40}}>Näiteid</h4>
                                <Part part_id={samples} className="samples" components={{p:P_to_div, a: Samples}}/>
                            </div>
                        </div>
                    </div>,
                ids)}
            </Slider>
        </div>
    </div>

}


export const Fields = ({className,id}) => {
    const ids = [23,24,25,26];
    const [slideIndex, setSlideIndex] = useState(0);
    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setSlideIndex(next)}

    const SlideSelect = (n) => ({children}) => {
        return <h3 
        className={slideIndex === n ? "active-header" : null} 
        onClick={() => {sliderRef.slickGoTo(n)}}>{children}</h3>
    }
    return <div id={id} className={className}>
        <h2 className={"about-subtitle"}>Valdkonnad</h2>
        <div className="headers">{addIndex(m)((id,n) => 
            <Part part_id={id} key={"field_header_" + n} components={{h3:SlideSelect(n),p:Empty}}/>,ids)}</div>
        <div className="slider-container">
            <Slider
                ref={x => {sliderRef = x}}
                {...settings}>
                {addIndex(m)((id,n) => 
                    <Part part_id={id} key={"field_" + n} className="with_cols" components={{h3:Empty}}/>,ids)}
            </Slider>
        </div>
    </div>
}