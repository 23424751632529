import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'ramda';
import { initialState } from './initial';

const set = (key) => (state, { payload }) => { state[key] = payload; };
const set_or_toggle = key => (state, { payload }) => 
  { state[key] = isNil(payload) ? !state[key] : payload; }

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    menu_open: set_or_toggle("menu_open"),
    show_info: set_or_toggle("show_info"),
    toggle_map: set_or_toggle("show_map"),
    toggle_filters: set_or_toggle("show_filters"),
    toggle_current: set_or_toggle("only_current"),
    show_all: set_or_toggle("show_all"),

    open_news: set("open_news"),
    set_width: set("width"),
    set_height: set("height"),
    set_svg_side: set("svg_side"),
    set_timeline: set("timeline_range"),
    set_yearrange: set("yearrange"),
    set_filter_value: set("filter_value"),
    set_selected: set("selected"),
    set_selected_filter: set("selected_filter"),
    set_filters: set("filters"),
    set_url: set("url"),
    set_all_data: set("all_data"),
    set_nodes: set("nodes"),
    set_links: set("links"),

    reset_departments: (state, { payload }) => { state.departments = payload; },
    set_departments: (state, { payload }) => { state.departments = { ...state.departments, 
      [payload]: { ...state.departments[payload], show: !state.departments[payload].show } }; },
  }
});

export const { 
  menu_open, show_info, set_selected, open_news, show_all, set_timeline, set_width, set_height, 
  set_svg_side, toggle_map, toggle_filters, toggle_current, set_selected_filter, set_departments, 
  reset_departments, set_yearrange, set_nodes, set_links, set_url, set_filters, set_all_data,
  set_filter_value
} = uiSlice.actions;

export default uiSlice.reducer;